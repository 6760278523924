<template>
    <PedidoResumido v-if="!showDetalhe" 
            v-on:showDetail="showDetail" 
            :matricula="currentUser.matricula" 
            :safra="safra" 
            :cultura="cultura" />
    <PedidoDetalhado v-if="showDetalhe" 
            v-on:showDetail="showDetail" 
            :matricula="currentUser.matricula" 
            :numeroPedido ="numeroPedido" 
            :safra="safra" 
            :cultura="cultura" />
</template>

<script lang="js">    
    import PedidoResumido from '../../components/extrato/PedidoResumido.vue'
    import PedidoDetalhado from '../../components/extrato/PedidoDetalhado.vue'

    export default {
        data(){
            return {
                showDetalhe: false,
                numeroPedido: null,
                safra: {},
                cultura: {},
            }
        },
         methods: {
            showDetail(value, pedido, safra, cultura) {
                this.showDetalhe = value;  
                if(value){
                    this.numeroPedido = pedido;
                }
                this.safra = safra;
                this.cultura = cultura;
            }
        },
        
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},

        components: { PedidoResumido, PedidoDetalhado }
    }

</script>