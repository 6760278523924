<template>
	<div class="col-12">
		<div class="card">
			<h5>Pedido Resumido</h5>
			<DataTable :value="pedidoList" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
				:rowHover="true" responsiveLayout="stack">
				<template #header>
					<div class="flex flex-column sm:flex-row">
						<Dropdown class="mb-2" placeholder="Safra" id="safra" v-model="safraSelecionada" :options="safras"
							optionLabel="descricao" style="width: 10rem;" />
						<Dropdown class="ml-3 mb-2" placeholder="Cultura" id="cultura" v-model="culturaSelecionada"
							:options="culturas" optionLabel="descricao" style="width: 10rem;" />
						<Button label="Consultar" @click="consultar" class="mb-2 ml-3" icon="pi pi-search"></Button>
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2 ml-3"
							@click="clearFilter1()" />
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>
				<Column header="" style="text-align: center">
					<template #body="data">
						<Button @click="$emit('showDetail', true, data.data.numeroPedido, safraSelecionada, culturaSelecionada)"
							type="button" class="mr-2" label="Detalhar" icon="pi pi-search"></Button>

						<!-- <Button v-if="possuiDocumento(data.data.numeroPedido)"
                			@click="irParaRota(data.data.numeroPedido)"
                			type="button" class="mr-2" label="Contrato (Gerenciar Assinatura)" icon="pi pi-file"></Button> -->
						<Button v-if="documentosExistentes[data.data.numeroPedido]" @click="irParaRota(data.data.numeroPedido)"
							type="button" class="mr-2" :label="ajustarNomeBotaoAssinar(data.data)" icon="pi pi-file-pdf">
						</Button>

					</template>
				</Column>
				<Column field="numeroPedido" header="Número Pedido" :sortable="true"
					style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ data.data.numeroPedido }}
					</template>
				</Column>
				<Column field="dataImplantacao" header="Data" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ formatDate(data.data.dataImplantacao) }}
					</template>
				</Column>
				<Column field="situacao" header="Situação" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ data.data.situacao }}
					</template>
				</Column>
				<Column field="safra" header="Safra" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ data.data.safra }}
					</template>
				</Column>
				<Column field="valorTotal" header="Total em aberto (R$)" :sortable="true"
					style="text-align: center; min-width:12rem">
					<template #body="data">
						{{ formatCurrency(data.data.valorTotal) }}
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import PedidoService from '../../service/PedidoService';
import Formatacao from '../../utilities/Formatacao';
import SafraService from '../../service/SafraService';
import CulturaService from '../../service/CulturaService';
import StorageService from '../../service/StorageService';
import AssinaturaEletronicaService from '../../service/AssinaturaEletronica';

export default {
	name: 'PedidoResumido',

	props: {
		matricula: {
			type: Number,
			required: true
		},
		safra: {
			type: Object,
			required: false,
		},
		cultura: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			pedidoList: [],
			safraSelecionada: this.safra,
			safras: [],
			culturaSelecionada: this.cultura,
			culturas: [],
			documentosExistentes: {},
			dadosDocumento: {}
		};
	},

	created() {
		this.consultar();
		this.buscarSafras();
		this.buscarCulturas();
	},

	methods: {
		formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
			return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
		},
		formatDate(data) {
			return Formatacao.formatDate(data);
		},

		clearFilter1() {
			this.safraSelecionada = '';
			this.culturaSelecionada = '';
			this.initFilters1();
		},

		buscarSafras() {
			SafraService.getSafras()
				.then(({ data }) => {
					this.safras = data;

					this.safras.unshift({
						codigo: "",
						descricao: "Todas"
					});
				});
		},

		buscarCulturas() {
			CulturaService.getCulturas().then(({ data }) => {
				if (data) {
					this.culturas = data;
				}

				this.culturas.unshift({
					codigo: "",
					descricao: "Todas"
				});
			});
		},

		consultar() {
			if (!this.safraSelecionada || typeof this.safraSelecionada == 'string') {
				this.safraSelecionada = this.safras.length > 0 ? this.safras[0] : null;
			}
			if (!this.culturaSelecionada || typeof this.culturaSelecionada == 'string') {
				this.culturaSelecionada = this.culturas.length > 0 ? this.culturas[0] : null;
			}

			PedidoService.getPedidosFiltro(this.$props.matricula, this.safraSelecionada.safraComposta, this.culturaSelecionada.codigo)
				.then((response) => {
					this.pedidoList = response.data;
					this.pedidoList.forEach(pedido => {
						this.verificarDocumento(pedido.numeroPedido); // Verifica o documento para cada pedido
					});
				});
		},

		ajustarNomeBotaoAssinar(pedido) {
			try {
				const documento = this.dadosDocumento[pedido.numeroPedido];
				const signers = documento?.signers || [];
				const gerente = signers?.find(signer => signer?.email?.includes('integrada.coop.br'));
				const produtor = signers?.find(signer => signer?.matricula != null);

				const gerenteAssinou = gerente.tokenAssinatura != null;
				const produtorAssinou = produtor.tokenAssinatura != null;

				const isAdmin = this.isAdministrador();

				if (gerenteAssinou && produtorAssinou) {
					return "Visualizar Documento";
				}

				if (isAdmin && !gerenteAssinou) {
					return "Assinar Documento";
				}

				return "Assinatura de Documento";
			} catch (error) {
				return "Assinatura de Documento";
			}
		},

		// possuiDocumento(numeroDocumento){
		// 	if(!this.isAdministrador()){
		// 		return false;
		// 	}

		// 	console.log(numeroDocumento);

		// 	(async () => {
		// 		const resultado = await consultarDocumento(numeroDocumento);
		// 		console.log('Documento existe:', resultado);

		// 		if(resultado !== null)
		// 			return true;

		// 	})();

		// 	return false;
		// },

		// async consultarDocumento(numeroDocumento){
		// 	try {
		// 		return AssinaturaEletronicaService.consultarEnvelopeNumeroDocumento(numeroDocumento)
		// 	} catch (error) {
		// 		console.error('Erro ao consultar o envelope: ', error);
		// 	}
		// },
		async verificarDocumento(numeroDocumento) {
			if (!this.isAdministrador()) {
				this.$nextTick(() => {
					this.documentosExistentes[numeroDocumento] = false;
				});
				return;
			}

			//console.log(numeroDocumento);

			try {
				const envelope = await this.consultarDocumento(numeroDocumento);
				// console.log('@envelope: ', envelope.data.numeroDocumento);

				this.$nextTick(() => {
					this.documentosExistentes[numeroDocumento] = envelope.data.numeroDocumento !== undefined;
					this.dadosDocumento[numeroDocumento] = envelope.data;
				});
			} catch (error) {
				console.error('Erro ao consultar o envelope: ', error);
				this.$nextTick(() => {
					this.documentosExistentes[numeroDocumento] = false;
				});
			}
		},

		async consultarDocumento(numeroDocumento) {
			try {
				return await AssinaturaEletronicaService.consultarEnvelopeNumeroDocumento(numeroDocumento);
			} catch (error) {
				console.error('Erro ao consultar o envelope: ', error);
			}
		},

		irParaRota(numeroDocumento) {
			console.log('Numero do Documento: ', numeroDocumento);
			this.$router.push(`/pedidos/assinatura-gerente/${numeroDocumento}`);
		},

		isAdministrador() {
			return StorageService.getAdmin() !== null;
		},

		// necessitaAssinatura(numeroDocumento){
		// 	return true;
		// }



	},
};
</script>
