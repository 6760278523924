<template>
    <div class="col-12">
        <div class="card">
            <h5>Pedido Detalhado</h5>  
            <DataTable :value="detalheList" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" responsiveLayout="stack">
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<div>
							<Button @click="$emit('showDetail', false, numeroPedido, safra, cultura)" 
									icon="pi pi-backward" type="button" class="p-button-outlined mb-2" label="Voltar"/>
						</div>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>				
                <Column field="codigoItem" header="Código Item" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{data.data.codigoItem}}
					</template>
				</Column>
                <Column field="descricao" header="Descrição" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{data.data.descricao}}
					</template>
				</Column>
                <Column field="quantidade" header="Quantidade" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{data.data.quantidade}}
					</template>
				</Column>                
                 <Column field="saldo" header="Saldo" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{data.data.saldo}}
					</template>
				</Column>
                <Column field="valor" header="Valor Unitário" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{formatCurrency(data.data.valor)}}
					</template>
				</Column>

                <ColumnGroup type="footer">
					<Row>
						<Column footer="Total em aberto (R$):" :colspan="4" style="text-align: right"/>
						<Column :footer="formatCurrency(totalPedido)" style="text-align: center"/>
					</Row>
				</ColumnGroup>
            </DataTable>
        </div>
    </div>

</template>

<script>
    import PedidoService from "../../service/PedidoService"
    import Formatacao from "../../utilities/Formatacao";


    export default {
        name: 'PedidoDetalhado', 

		props: {
			matricula: {
				type: Number,
				required: true
			},
			numeroPedido: {
				type: Number,
				required: true
			},
            safra: {
				type: Object,
				required: false,
			},
			cultura: {
				type: Object,
				required: false,
			},
			
		},
        data(){
            return {
                totalPedido: null,
                detalheList: [],
            }
        },

        created(){
            this.getPedidos();
        },
        
        methods: {
            getPedidos() {                
				PedidoService.getDetalhePedido(this.$props.matricula, this.$props.numeroPedido).then((response) => {
                    this.totalPedido = response.data.valorTotal;
                    this.detalheList = response.data.detalhes;
                });
            },
			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			}

        }
       
    }
    
</script>